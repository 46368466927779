import { useEffect } from "react";

const usePreventZoomOnFocus = (): void => {
  useEffect(() => {
    // Detect if the user is on an iOS device
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    if (!isIOS) return; // Exit if not iOS

    const viewport = document.querySelector("meta[name=viewport]");
    if (!viewport) return;

    const handleFocusIn = (event: FocusEvent) => {
      const target = event.target as HTMLElement;
      if (target.tagName === "INPUT" || target.tagName === "TEXTAREA" || target.tagName === "SELECT") {
        viewport.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1");
      }
    };

    const handleFocusOut = () => {
      viewport.setAttribute("content", "width=device-width, initial-scale=1");
    };

    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusOut);

    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.removeEventListener("focusout", handleFocusOut);
    };
  }, []);
};

export default usePreventZoomOnFocus;