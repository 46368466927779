import axios, {
  AxiosInstance,
  CancelTokenStatic,
  InternalAxiosRequestConfig,
} from 'axios';
import LocalStorage from '../LocalStorage';
import AuthService from '../AuthService';

interface CustomAxiosInstance extends AxiosInstance {
  CancelToken?: CancelTokenStatic;
  isCancel?(value: any): boolean;
}

const AxiosClient: CustomAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL!!,
});

AxiosClient.CancelToken = axios.CancelToken;
AxiosClient.isCancel = axios.isCancel;

AxiosClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  // One sample custom header referring to authorization secret provided
  const accessToken = LocalStorage.get.accessToken();
  if (accessToken) {
    config.headers!!.Authorization = `Bearer ${LocalStorage.get.accessToken()}`;
  }

  return config;
});

AxiosClient.interceptors.response.use(response => response, (error) => {
  const previousConfig = error.config;
  const refreshToken = LocalStorage.get.refreshToken();
  if (error.response?.status === 401 && !previousConfig?.sent) {
    previousConfig.sent = true;

    if (!refreshToken) {
      return Promise.reject(error);
    }

    return AuthService.refreshAccessToken(refreshToken).then(response => {
      if (response?.status === 200) {
        const newAccessToken = response.data?.access;
        LocalStorage.set.accessToken(newAccessToken);
        previousConfig.headers.Authorization = `Bearer ${newAccessToken}`;
        return AxiosClient(previousConfig);
      }
    }).catch((error) => {
      LocalStorage.clear();
      window.location.href = '/authentication';
      return Promise.reject(error);
    })
  } else if (error.response?.status === 403) {
    LocalStorage.clear();
    window.location.href = '/authentication';
  } else {
    return Promise.reject(error);
  }
});

export default AxiosClient;
