import Typography from "../../../models/typography";
import { APP_ELEMENTS_AND_CLASS, FONT_STYLES } from "../constants";

const veromodaGirlFont: Typography[] = [
  {
    element: APP_ELEMENTS_AND_CLASS.BODY.value,
    src: "/assets/fonts/FavoritVM/regular.otf",
    fontFamily: "favoritVM",
    fontWeight: 'normal',
    style: FONT_STYLES.NORMAL,
  },
  {
    element: APP_ELEMENTS_AND_CLASS.HEADLINE.value,
    src: "/assets/fonts/FavoritVM/bold.otf",
    fontFamily: "favoritVM",
    fontWeight: '800',
    style: FONT_STYLES.NORMAL,
    isCustomTag: true
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H1.value,
    src: "/assets/fonts/FavoritVM/bold.otf",
    fontFamily: "favoritVM",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H2.value,
    src: "/assets/fonts/FavoritVM/bold.otf",
    fontFamily: "favoritVM",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H3.value,
    src: "/assets/fonts/FavoritVM/bold.otf",
    fontFamily: "favoritVM",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H4.value,
    src: "/assets/fonts/FavoritVM/bold.otf",
    fontFamily: "favoritVM",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H5.value,
    src: "/assets/fonts/FavoritVM/bold.otf",
    fontFamily: "favoritVM",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H6.value,
    src: "/assets/fonts/FavoritVM/bold.otf",
    fontFamily: "favoritVM",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.CUSTOM_FONT.value,
    src: "/assets/fonts/FavoritVM/regular.otf",
    fontFamily: "favoritVM",
    fontWeight: 'normal',
    style: FONT_STYLES.NORMAL,
    isCustomTag: true
  }
]


export default veromodaGirlFont;